<template lang="pug">
    #spinner
        div(class="lds-ring")
            div
            div
            div
            div
</template>

<script>
export default {
    name: "component-spinner",
}
</script>

<style lang="stylus" scoped src="./Spinner.styl"></style>