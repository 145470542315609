<template lang="pug">
	Aside#empty(:ativo="$store.state.toggleEmptyUnits" #default="{ beforeClose }")
		h2 Obrigado pelo seu interesse em nossos produtos, mas não temos representantes em sua região.
		p Caso a região e/ou canal não estejam listados, por gentileza, entre em contato com a nossa equipe comercial, pelo telefone:
		p.green (44) 3599-8000
		button(@click="beforeClose") Fechar
</template>

<script>
import Aside from '@components/Aside/Aside'

export default {
	name: "component-empty-units",
	components: {
		Aside,
	},
}
</script>

<style lang="stylus" scoped src="./EmptyUnits.styl"></style>
