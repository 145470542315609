<template lang="pug">
	section#options
		.subOption
			p.subTitle Buscar por Pontos de Venda
			.search
				input(placeholder="Digite um endereço para pesquisar pontos de venda próximos" v-model="search" @keyup.enter="getProximos()")
				.searchIcon(@click="getProximos()")
					img(src="@images/paginas/onde-encontrar/icone-busca.png")
			.map(ref='map')
				.backdrop(v-if="searching")
				Spinner(v-if="searching").spinner
			.filter
				.results
					span <strong>{{clientes.length}} resultados</strong> encontrados
			ul(v-if="map").units
				li(v-for="cliente in filteredList")
					p(v-if="cliente.name").name {{cliente.name}}
					p(v-if="cliente.telephone").text <strong>Contato: </strong> {{cliente.telephone}}
						a(:href="`https://wa.me/${cliente.telephone.replaceAll(/[() -]/g, '')}`")
							span(v-if="cliente.isWhats")
								img(src="@images/whatsapp.png").isWhats
					p(v-if="cliente.cnpj").text <strong>CNPJ: </strong> {{cliente.cnpj}}
					p(v-if="cliente.insEstadual").text <strong>Inscrição Estadual: </strong> {{cliente.insEstadual}}
					p(v-if="cliente.address").text <strong>Endereço: </strong> {{cliente.address}}, {{cliente.number}} {{cliente.complement}}
					p(v-if="cliente.city").text <strong>Cidade: </strong> {{cliente.city}}
					p(v-if="cliente.uf").text <strong>Estado: </strong> {{cliente.uf}}
					p(v-if="cliente.dsBairro").text <strong>Bairro: </strong> {{cliente.dsBairro}}
					p(v-if="cliente.cep").text <strong>CEP: </strong> {{cliente.cep}}
					hr
</template>

<script>
import Spinner from '@components/Spinner/Spinner'
export default {
	name: "section-options",
	data() {
		return {
			map: null,
			search: '',
			options: {
				clientes: []
			},
			searching: false,
			apiMapbox: '',
		}
	},
	components: {
		Spinner
	},
	methods: {
		getProximos() {
			this.searching = true
			this.removeMarkers()
			this.$axios.get(`/coamo-api/alimentos/proximos?address=${this.search}`)
			.then(response => {
				this.options.clientes = response.data.clientes
				this.addMarkers()
				let lat = response.data.clientes.reduce((acc, curr) => acc + curr.geoLocalization.lat, 0) / response.data.clientes.length;
				let long = response.data.clientes.reduce((acc, curr) => acc + curr.geoLocalization.long, 0) / response.data.clientes.length;
				this.map.setCenter([long, lat]);
				this.map.setZoom(9);
				this.searching = false;
			})
		},
		createMap() {
			for (let setting of this.settings) {
                if (setting.attribute === 'apiMapbox') this.apiMapbox = setting.value;
            }
			mapboxgl.accessToken = `${this.apiMapbox}`
			this.map = new mapboxgl.Map({
				container: this.$refs.map,
				style: 'mapbox://styles/mapbox/streets-v12',
				center: [-52.461213, -24.0427725],
				zoom: 7
			})
		},
		removeMarkers() {
			this.clientes.forEach(cliente => {
				cliente.marker.remove()
			})
			this.options.clientes = [];
		},
		addMarkers() {
			this.clientes.forEach(cliente => {
				this.$set(cliente, 'marker', new mapboxgl.Marker({ color: '#5d9e2f', anchor: 'center' })
				.setLngLat([cliente.geoLocalization.long, cliente.geoLocalization.lat])
				.addTo(this.map))
			})
		}
	},
	computed: {
		filteredList() {
			const lista = this.clientes.map(option => {
				if (option.marker.addTo !== undefined){
					option.marker.addTo(this.map)
				}
				return option
			})
			return lista
		},
		clientes() {
			return this.options.clientes.map(cliente => {
				return {...cliente, marker: cliente.marker || {}}
			})
		},
		settings() {
			return this.$store.state.settings
		}
	},
	created() {
		this.$store.dispatch('fetchSettings')
	},
	watch: {
        settings: function () {
            this.createMap() 
        }
    }
}
</script>

<style lang="stylus" scoped src="./Alimentos.styl"></style>
