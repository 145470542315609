<template lang="pug">
	main#ondeencontrar
		Banner(:contents="banner")
		Options
</template>

<script>
import Banner from '@sections/Pagina/Banner/Banner'
import Options from '@sections/OndeEncontrar/Options/Options'

export default {
	name: "view-fale-conosco",
	components:{
		Banner,
		Options,
	},
	data(){
		return {
			banner: {
				banner: {
					alt: `Onde Encontrar`,
					url: require(`@images/paginas/banner-fale-conosco.jpg`),
				},
				title: `Onde Encontrar`
			},
		}
	},
	metaInfo() {
		return {
			title: 'onde-encontrar',
			meta: [
				{ name: 'description', content: '' },
			],
		}
	},
}
</script>

<style lang="stylus" scoped src="./OndeEncontrar.styl"></style>