<template lang="pug">
    section#banner
        picture.img
            img(:src="contents.banner.url", :alt="contents.banner.altText")
        h2 {{ contents.title }}
</template>

<script>
import { props } from '@/mixins/component.js'

export default {
    name: "section-banner",
    props,
}
</script>

<style lang="stylus" scoped src="./Banner.styl"></style>
